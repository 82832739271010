import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, RouterStateSnapshot, TitleStrategy } from '@angular/router';

import { TranslationData } from '@stsm/i18n/models/translation-data';
import { TranslationService } from '@stsm/i18n/services/translation.service';
import { EnvironmentBase } from '@stsm/shared/models/environment-base';
import { ENVIRONMENT } from '@stsm/shared/tokens/environment.token';

const APP_NAME_STSM: string = 'StudySmarter';
const APP_NAME_VAIA: string = 'Vaia';
const APP_NAME_CONTENT: string = 'StudySmarter Content';

@Injectable({ providedIn: 'root' })
export class TitleService extends TitleStrategy {
  private get _appName(): string {
    return this._environment.CONTENT_CREATORS
      ? APP_NAME_CONTENT
      : this._environment.TARGET_MARKET === 'core'
        ? APP_NAME_STSM
        : APP_NAME_VAIA;
  }

  constructor(
    @Inject(ENVIRONMENT) private readonly _environment: EnvironmentBase,
    private readonly _translationService: TranslationService,
    private readonly _title: Title,
  ) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot): void {
    const title: string | undefined = this.buildTitle(routerState);

    if (title !== undefined) {
      this._title.setTitle(`${this._translationService.get(title)}${this._getTitleSuffix()}`);
    } else {
      this._title.setTitle(this._getParentTitle(routerState.root) ?? this._appName);
    }
  }

  setTitle({ translationKey, params }: TranslationData = { translationKey: this._appName }): void {
    this._title.setTitle(this._translationService.get(translationKey, params) + this._getTitleSuffix());
  }

  /**
   * Title suffix which gets appended to the Doc title but not to the toolbar
   */
  private _getTitleSuffix(): string {
    return ` - ${this._appName}`;
  }

  private _getParentTitle(route: ActivatedRouteSnapshot): string | undefined {
    if (!route) {
      return undefined;
    }

    if (!route.title && route.parent) {
      return this._getParentTitle(route.parent);
    }
    const title = this.getResolvedTitleForRoute(route);

    return title;
  }
}
